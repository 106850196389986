<template>
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Top 5 Risks</h3>

    </div>
    <div class="card-body p-0" style="max-height:400px;min-height:400px;overflow-y: hidden;">
        <b-table
          :items="threatdata"
          :fields="threatFields"
        >
          <template #cell(risk)="data">
            <div class="transparentrisk" :style="riskclass(data.item.impact,data.item.probability)">{{data.item.risk}}</div>
          </template>
          <template #cell(name)="data">
            <span style="font-size:12px">{{data.item.reference}} - {{data.item.name}}</span>
          </template>          
        </b-table>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RiskCalcs from "@/core/services/helpers/risk.calcs";
export default {
  name: "widget-7",
  props: {
    threatdata: Object,
  },      
  data() {
    return {
      threatFields: [
        { key: "name", label: "Threat", sortable: true },
        { key: "risk" , sortable: true },
      ],        
    };
  },
  computed: {
    ...mapGetters(["layoutConfig","currentUser"]),
  },    
  methods: {
    riskclass(impact,probability) {

        return RiskCalcs.getRiskColor(impact,probability,this.currentUser.riskreview)
    },          
  },
  mounted() {

    console.log('bob',this.threatdata)



  },
};
</script>
