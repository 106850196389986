<template>
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Top 5 Controls with Risks</h3>

    </div>
    <div class="card-body p-0" style="max-height:400px;min-height:400px;overflow-y: hidden;">
        <b-table
          :items="systems"
          :fields="threatFields"
        >
          <template #cell(name)="data">
            <span>{{data.item.name}}</span>
          </template>          
        </b-table>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "widget-7",
  props: {
    systems: Object,
  },      
  data() {
    return {
      threatFields: [
        { key: "name", label: "Control", sortable: true },
        { key: "count", label: "Risks" , sortable: true },
      ],        
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },    
  methods: {        
  },
  mounted() {

    console.log('bob',this.threatdata)



  },
};
</script>
